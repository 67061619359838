import React from "react";
import { Container, Row, Col } from "react-bootstrap"
import SectionHeading from "./sectionHeading";


export default class ReviewButton extends React.Component {
  render() {
    return (
    <div className='cg-sectionShaded' style={{ textAlign: 'center', padding:'5vh 5vw' }} >
      <SectionHeading>Leave a Review</SectionHeading>
      <Container>
        <Row>
          <Col>
            <a href="https://g.page/r/CdtVF8UDJIl_EBI/review"  target="_blank" rel="noopener noreferrer" className="btn btn-primary cg-dark">Review Us on Google</a>
          </Col>
        </Row>
      </Container>
    </div>
    );
  }
}