import React from "react";
//import { useState, useEffect } from "react"; // JSONData at runtime
import SEO from "../components/seo";
import Hr from "../components/hr";
import Header from "../components/header";
//import FeedbackForm from "../components/feedbackForm";
import ReviewButton from "../components/googleReviewBtn";
import ContactForm from "../components/contactForm";
import SectionHeading from "../components/sectionHeading";
import Layout from "../components/layout";
import StyledBackgroundSection from "../components/background";
import { 
  Container, 
  Row, 
  Col, 
} from "react-bootstrap"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowAltCircleUp, faArrowAltCircleDown } from '@fortawesome/free-regular-svg-icons'
//import fetch from 'node-fetch'; // JSONData at runtime
import Review from  "../components/review";
import AboveFold from  "../components/aboveFold";
import '../components/reviews.css';
import reviews from '../../content/review-data.json'; // JSONData at buildtime
import { useStaticQuery, graphql } from "gatsby";

const Reviews = () => {
    const data = useStaticQuery(graphql`
    query {
      wideDivider1: file(relativePath: { eq: "roofing1.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }`);
  // // JSONData at runtime
  // const [reviews, setReviews] = useState([]);
  // useEffect(() => {
  //   //fetch(`https://wedocny.uticarome.net/reviews.json`)
  //   fetch('/review-data.json') // TODO separate dev from prod?
  //     .then(response => response.json()) // parse JSON from request
  //     .then(resultData => {
  //       setReviews(resultData)
  //     }).catch(err => console.error(err));
  // }, [])

  return (
  <Layout>
    <SEO title="Reviews | Testimonials" path='reviews' />

    <Header/>
    
    <ReviewButton/>
    
    <main id='main'>
    
      <div style={{ textAlign:'center' }} className='cg-sectionShaded'>
        <Container>
          <Row>
            <Col>
              <SectionHeading>Reviews</SectionHeading>
            </Col>
          </Row>

          {reviews.map( (item,key) =>
            <Row key={item.order}>
              <Col style={{ marginTop:'2em' }}>
                <Review title={item.Title} name={item.Name} thumbtack={!! ("Thumbtack" === item.Source)}>
                  {item.Comments}
                </Review>
              </Col>
            </Row>
          )}
          
          <Row>
            <Col style={{ padding:'2rem' }}>
               To get service like this <FontAwesomeIcon icon={faArrowAltCircleUp} /> contact us! <FontAwesomeIcon icon={faArrowAltCircleDown} /> 
            </Col>
          </Row>
        </Container>
      </div>
    </main>
    
    <Hr img={data.wideDivider1.childImageSharp.fluid}/>
    
    <ContactForm />
  </Layout>
  )
};

export default Reviews;
